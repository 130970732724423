import React, { useState, createContext } from 'react';

export const NavigationContext = createContext({
	anchor: null,
	setAnchor: () => { },
	isLoaded: false,
	setIsLoaded: () => { },
});

const NavigationProvider = ({children}) => {
	const [anchor, setAnchor] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);

	return (
		<>
			<NavigationContext.Provider
				value={{
					anchor: anchor,
					setAnchor: setAnchor,
					isLoaded: isLoaded,
					setIsLoaded: setIsLoaded,
				}}>
				{children}
			</NavigationContext.Provider>
		</>
	);
}

export default ({element}) => (
	<NavigationProvider>
		{element}
	</NavigationProvider>
);